import React from 'react';
import { YMaps, Map, Placemark } from "react-yandex-maps";
import "./YandexMap.scss";

export const YandexMap = () => {

    const mapData = {
        center: [51.164652, 71.441883],
        zoom: 15,
    };

    const coordinates = [
        [51.164652, 71.441883]
    ];

    return (
        <YMaps>
            <Map className="y-map" defaultState={mapData}>
                {coordinates.map((coordinate, index) => <Placemark key={index} geometry={coordinate} />)}
            </Map>
        </YMaps>
    );
};