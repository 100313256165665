import React, {FC} from 'react';
import "./Contacts.scss";
import {YandexMap} from "../../common/YandexMap/YandexMap";
import {Button} from "../../common/Button/Button";

type ContactsType = {
    isFormActive: boolean,
    setIsFormActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const Contacts: FC<ContactsType> = ({isFormActive, setIsFormActive}) => {

    return (
        <div className="contacts" id="contacts">
            <h2 className="contacts-title">Контакты</h2>
            <YandexMap/>
            <div className="contacts-footer">
                <div className="footer-data">
                    <div className="address">
                        <h3 className="address-title">Адрес</h3>
                        <p className="address-text">
                            Казахстан, город Нур-Султан, район Байконыр, улица Амангелдi Иманов, здание 19
                        </p>
                    </div>
                    <div className="email">
                        <h3 className="address-title">E-mail</h3>
                        <p className="address-text">
                            info@sitronics.kz
                        </p>
                    </div>
                </div>
                <Button isFormActive={isFormActive} setIsFormActive={setIsFormActive} className="footerBtn"/>
            </div>
        </div>
    );
};