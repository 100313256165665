import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import "./ModalForm.scss";
import PythonApi from '../../services/PythonApi'

export type Inputs = {
    theme: string,
    name: string,
    company: string,
    phone: string,
    email: string,
    subject: string
    captcha: string
};

type ModalFormType = {
    isFormActive: boolean,
    setIsFormActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const ModalForm: FC<ModalFormType> = ({isFormActive, setIsFormActive}) => {

    const { register, handleSubmit, reset, setError, resetField } = useForm<Inputs>();
    const [captchaSrc, setCaptchaSrc] = useState('')

    const onSubmit: SubmitHandler<Inputs> = async data => {
        const { captcha, ...template_params } = data

        const res = await PythonApi.sendEmail(template_params, captcha)

        if (res.status === 200) {
            reset()
            setIsFormActive(!isFormActive)
            return
        }

        if (res.headers.get("content-type") === "application/json") {
            const { result } = await res.json()
            console.log(result === "captcha error")
            if (result === "captcha error") {
                updateCaptcha()
                resetField("captcha")
                setError("captcha", { type: "manual", message: "Код с картинки введён неверно" }, { shouldFocus: true })
            }
        }
    }

    const updateCaptcha = useCallback(async () => {
        setCaptchaSrc(await PythonApi.getCaptcha())
    }, [])

    useEffect(() => {
        updateCaptcha()
    }, [updateCaptcha])

    return (
        <div id="popup" className="popup" onClick={()=> setIsFormActive(false)}>
            <div className="popup__body" >
                <div className="form-wrapper" onClick={e=> e.stopPropagation()}>
                    <h2 className="form-title">Оставить заявку</h2>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <label className="form-item" htmlFor="theme">
                            Тема обращения
                            <input type="text" placeholder="Впишите тему" {...register("theme")}/>
                        </label>
                        <label className="form-item" htmlFor="name">
                            Ваше имя*
                            <input type="text" placeholder="ФИО" {...register("name", {required: true})}/>
                        </label>
                        <label className="form-item" htmlFor="company">
                            Компания*
                            <input type="text" placeholder="Ваша компания" {...register("company", {required: true})}/>
                        </label>
                        <label className="form-item" htmlFor="phone">
                            Телефон*
                            <input type="text" placeholder="+7" {...register("phone", {required: true})}/>
                        </label>
                        <label className="form-item" htmlFor="email">
                            Эл.почта*
                            <input type="text" placeholder="company@mail.com" {...register("email", {required: true})}/>
                        </label>
                        <label className="form-item" htmlFor="subject">
                            Чем мы можем вам помочь?
                            <textarea placeholder="Ваш вопрос" {...register("subject")}/>
                        </label>
                        <label className="form-item form-item-captcha" htmlFor="captcha">
                          <img src={captchaSrc} alt="" />
                          <button type='button' onClick={updateCaptcha}>↻</button>
                            <input
                                type="text"
                                placeholder="Введите код с картинки*"
                                {...register("captcha", { required: true })}
                            />
                        </label>
                        <button type="submit"  className="formBtn">Отправить</button>
                    </form>
                    <span>*-поля обязательные для заполнения</span>
                </div>
            </div>
        </div>
    );
};