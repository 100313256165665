type LenovoIconProps = {
    className?: string
}

export const LenovoIcon =({className}: LenovoIconProps)=> {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="450" height="151" viewBox="0 292.369 609.697 203.233">
            <path fill="#E22319" d="M0 292.369h609.697v203.233H0z"/>
            <path fill="#FFF" d="M265.411 360.88c-9.212 0-18.999 4.03-25.332 12.666v-10.938h-21.302v78.299h21.302V396c0-8.06 6.333-16.696 17.848-16.696 8.636 0 17.848 6.333 17.848 16.696v44.331h21.302V391.97c.575-17.848-12.667-31.09-31.666-31.09M450.22 362.032l-19.574 53.542-19.576-53.542h-24.18l32.241 78.299h23.604l31.665-78.299zM195.748 416.15c-9.212 6.909-14.394 8.061-22.454 8.061-7.484 0-13.242-2.303-17.272-6.333l54.694-23.029c-1.151-8.636-4.606-16.121-9.211-21.878-7.485-8.636-17.848-12.666-31.089-12.666-23.605 0-41.453 17.272-41.453 40.301 0 23.605 17.848 40.302 43.755 40.302 14.393 0 29.362-6.909 36.271-14.97l-13.241-9.788zm-41.453-30.513c3.455-5.182 9.211-8.06 16.696-8.06 7.484 0 13.817 4.606 16.696 10.939l-37.422 15.545c-1.151-7.485 1.152-13.818 4.03-18.424zM127.236 420.756H78.299v-81.753H56.421v101.328h70.815zM512.975 441.482c-23.604 0-42.028-17.271-42.028-40.301 0-22.454 18.423-40.301 42.028-40.301 23.604 0 42.028 17.272 42.028 40.301.575 23.03-17.848 40.301-42.028 40.301m0-62.178c-12.091 0-20.727 9.212-20.727 21.878 0 12.09 9.212 21.878 20.727 21.878 12.09 0 20.727-9.212 20.727-21.878.574-12.091-8.638-21.878-20.727-21.878M347.74 441.482c-23.604 0-42.028-17.271-42.028-40.301 0-22.454 18.424-40.301 42.028-40.301 23.605 0 42.028 17.272 42.028 40.301s-18.422 40.301-42.028 40.301m0-62.178c-12.09 0-20.727 9.212-20.727 21.878 0 12.09 9.212 21.878 20.727 21.878 12.091 0 20.727-9.212 20.727-21.878.575-12.091-8.636-21.878-20.727-21.878M558.457 431.119h-3.454v-1.727h9.212v1.727h-3.455v9.212h-2.303v-9.212zm8.061-1.726h2.303l3.454 5.182 3.455-5.182h2.303v11.515h-2.303v-8.061l-3.455 5.182-3.454-5.182v8.061h-2.303v-11.515z"/>
        </svg>
    )
}