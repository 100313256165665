import { blobToBase64 } from "../utils/utils"
import { Inputs } from "../common/ModalForm/ModalForm"

export default class PythonApi {
    static async getCaptcha(): Promise<string> {
        const res = await PythonApi.fetch("/get-captcha")
        const blob = await res.blob()

        return blobToBase64(blob)
    }

    static async sendEmail(template_params: Omit<Inputs, "captcha">, captcha: string) {
        return  await PythonApi.fetch("/email", "POST", { template_params, captcha })
    }

    private static fetch(path: string, method = "GET", data?: any) {
        const params: RequestInit = {
            method,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
        }
        console.log(params);

        if (data)
            if (method === "GET")
                path += "?" + new URLSearchParams(data).toString()
            else
                params.body = JSON.stringify(data)

        return fetch(`/python-api${path}`, params)
    }
}
