import React from 'react';
import  "./Partners.scss";
import {HuaweiIcon} from "../../common/Icons/HuaweiIcon";
import {LenovoIcon} from "../../common/Icons/LenovoIcon";

export const Partners = () => {
    return (
        <div className="partners" id="partners">
            <h2 className="partners-title">Партнёры</h2>
            <div className="partners-brands">
                <HuaweiIcon className="huawei" />
                <LenovoIcon className="lenovo"/>
            </div>
        </div>
    );
};