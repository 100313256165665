import React, {FC, useState} from 'react';
import "./Header.scss"
import {Button} from "../../common/Button/Button";
import {HashLink as Link} from "react-router-hash-link";
import {BurgerIcon} from "../../common/Icons/BurgerIcon";
import {MobileHeader} from "./MobileHeader";

type HeaderType = {
    isFormActive: boolean,
    setIsFormActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const Header: FC<HeaderType> = ({isFormActive, setIsFormActive}) => {

    const [isMobile, setIsMobile] = useState<boolean>(false);

    return (
        <div className="header-wrapper" id="header">
            {!isMobile && <div className="header">
                <h1>SITRONICS CLOUD KZ</h1>
                <ul className="header-block">
                    <li>
                        <Link to="#header">О компании</Link>
                    </li>
                    <li>
                        <Link to="#areas">Направления деятельности</Link>
                    </li>
                    <li>
                        <Link to="#partners">Партнёры</Link>
                    </li>
                    <li>
                        <Link to="#contacts">Контакты</Link>
                    </li>
                </ul>
                <div onClick={()=> setIsMobile(true)} className="burger">
                    <BurgerIcon />
                </div>
            </div>}

            {!isMobile && <div className="about-wrapper">
                <div className="about">
                    <h2 className="about-head">О компании</h2>
                    <p className="about-text">
                        <span>Sitronics Cloud KZ —</span> молодой провайдер облачной инфраструктуры и сервисов информационной безопасности.
                        Мы предлагаем услуги публичного, частного и гибридного облаков для решения полного спектра бизнес-задач.
                    </p>
                </div>
                <Button isFormActive={isFormActive} setIsFormActive={setIsFormActive} className="headerBtn"/>
            </div>}

            {isMobile && <MobileHeader isFormActive={isFormActive} setIsFormActive={setIsFormActive} setIsMobile={setIsMobile}/>}
        </div>
    );
};