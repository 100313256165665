import React, {useState} from 'react';
import "./App.scss";
import {Header} from "./components/Header/Header";
import {Areas} from "./components/Areas/Areas";
import {Partners} from "./components/Partners/Partners";
import {Contacts} from "./components/Contacts/Contacts";
import {BrowserRouter} from "react-router-dom";
import {ModalForm} from "./common/ModalForm/ModalForm";

function App() {

  const [isFormActive, setIsFormActive] = useState<boolean>(false);

  return (
    <BrowserRouter>
        <div className="main-wrapper">
            <Header isFormActive={isFormActive} setIsFormActive={setIsFormActive}/>
            <Areas/>
            <Partners/>
            <Contacts isFormActive={isFormActive} setIsFormActive={setIsFormActive}/>
        </div>

        {isFormActive && <ModalForm isFormActive={isFormActive} setIsFormActive={setIsFormActive}/>}

    </BrowserRouter>
  );
}

export default App;
