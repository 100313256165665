import React from 'react';
import "./Areas.scss";
import {LaasIcon} from "../../common/Icons/LaasIcon";
import {BaasIcon} from "../../common/Icons/BaasIcon";
import {SaasIcon} from "../../common/Icons/SaasIcon";
import {VDIIcon} from "../../common/Icons/VDIIcon";
import {AreasItem} from "./AreasItem";

type ActivitiesType = {
    id: string,
    title: string,
    text: string,
    icon: JSX.Element
}

export const Areas = () => {

    const activities: ActivitiesType[] = [
        {
            id: '1',
            title: 'Виртуальная инфраструктура (IaaS):',
            text: 'полноценная замена физической инфраструктуры на базе современного оборудования от ведущих вендоров, без капитальных затрат на закупку собственного оборудования и с широкими возможностями масштабирования бизнеса.',
            icon: <LaasIcon/>
        },
        {
            id: '2',
            title: 'Сервис для резервного копирования данных (BaaS):',
            text: 'позволяет как самостоятельно, так и с помощью наших специалистов, настраивать резервное копирование любых ваших данных, как хранимых в нашем облаке, так и на ваших ресурсах.',
            icon: <BaasIcon/>
        },
        {
            id: '3',
            title: 'Аренда ПО (SaaS):',
            text: 'аренда программного обеспечения с помесячной оплатой, без необходимости приобретать пакеты лицензий в постоянное владение. Предлагаем как инфраструктурные лицензии (серверное программное обеспечение), так и пользовательские лицензии (офисные приложения).',
            icon: <SaasIcon/>
        },
        {
            id: '4',
            title: 'Удаленные рабочие места (VDI):',
            text: 'полноценные, оснащенные всем необходимым программным обеспечением, рабочие места. Позволяют значительно сократить расходы на закупку дорогостоящей техники для сотрудников, оперативно управлять количеством требуемых лицензий, расширять и сужать список приложений, нужных для каждого сотрудника.',
            icon: <VDIIcon/>
        }
    ]

    return (
        <div className="areas" id="areas">
            <h2 className="areas-title">Направления деятельности</h2>
            <div className="areas-block">
                {activities.map(item => (
                    <AreasItem key={item.id} icon={item.icon} itemTitle={item.title} itemText={item.text}/>
                ))}
            </div>
        </div>
    );
};