import React, {FC, useState} from 'react';
import cn from "classnames";

type AreasItemType = {
    icon: JSX.Element,
    itemTitle: string,
    itemText: string
}

export const AreasItem: FC<AreasItemType> = ({icon, itemText, itemTitle}) => {

    const [isActive, setIsActive] = useState<boolean>(false)

    return (
        <div className="areas-block__item">
            <div onClick={()=> setIsActive(!isActive)} className="title-block">
                {icon}
                <h3 className="item-title">{itemTitle}</h3>
            </div>
            <p className={cn("item-text", isActive && "text-active")}>
                {itemText}
            </p>
        </div>
    );
};