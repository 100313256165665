import React from 'react';


type ButtonType = {
    className: string,
    isFormActive: boolean,
    setIsFormActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const Button = ({className, isFormActive, setIsFormActive}: ButtonType) => {
    return (
        <button type="button" onClick={()=> setIsFormActive(!isFormActive)} className={className}>Обратная связь</button>
    );
};