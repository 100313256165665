import React, {FC} from 'react';
import {CloseIcon} from "../../common/Icons/CloseIcon";
import {HashLink as Link} from "react-router-hash-link";
import {ArrowRightIcon} from "../../common/Icons/ArrowRightIcon";

type MobileHeaderType = {
    isFormActive: boolean,
    setIsFormActive: React.Dispatch<React.SetStateAction<boolean>>,
    setIsMobile: React.Dispatch<React.SetStateAction<boolean>>
}

type LinkListType = {
    id: string,
    title: string,
    linkTo: string
}

export const MobileHeader: FC<MobileHeaderType> = ({isFormActive, setIsFormActive, setIsMobile}) => {

    const linkList: LinkListType[] = [
        {
            id: "1",
            title: "О компании",
            linkTo: "#header"
        },
        {
            id: "2",
            title: "Направления деятельности",
            linkTo: "#areas"
        },
        {
            id: "3",
            title: "Партнёры",
            linkTo: "#partners"
        },
        {
            id: "4",
            title: "Контакты",
            linkTo: "#contacts"
        },
    ]

    return (
        <div className="mobile">
            <div onClick={()=> setIsMobile(false)} className="mobile-close">
                <CloseIcon/>
            </div>
            <div className="mobile-list">
                <ul>
                    {
                        linkList.map(item => (
                            <li key={item.id}>
                                <Link to={item.linkTo}>
                                    {item.title}
                                    <ArrowRightIcon/>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <p onClick={()=> setIsFormActive(!isFormActive)} className="mobile-feedback">Обратная связь</p>
        </div>
    );
};